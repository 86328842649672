<template>
  <div v-if="rows.length !== 0">
    <b-table
      small
      :items="rows"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      responsive
      striped
      foot-clone
    >
      <template #cell(datetime)="data">
        {{ getFormattedDate(new Date(data.value)) }}
      </template>

      <template #cell(impressions)="data">
        {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
      </template>

      <template #cell(clicks)="data">
        {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
      </template>

      <template #cell(ecpm)="data">
        {{ currency('USD', data.value) }}
      </template>

      <template #foot(datetime)="data">
        <strong :data-label="data.label">{{ $t('Records') }}: {{ rows.length }}</strong>
      </template>

      <template #foot(impressions)="data">
        <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalFormat('impressions')) }}</strong>
      </template>

      <template #foot(clicks)="data">
        <strong :data-label="data.label">{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalFormat('clicks')) }}</strong>
      </template>

      <template #foot(ecpm)="data">
        <strong :data-label="data.label">{{ currency('USD', totalAmount) }}</strong>
      </template>
    </b-table>
  </div>
</template>
<script>
import {
  BTable,
} from 'bootstrap-vue'
import currencyFormatter from '@/utils/currency-formatter'
import getFormattedDate, { getFormattedDateTime } from '@/utils/date-formatter'

export default {
  components: {
    BTable,
  },
  props: {
    rows: {
      type: Array,
      required: true,
      deafult: () => [],
    },
    fields: {
      type: Array,
      required: true,
      deafult: () => [],
    },
    range: {
      type: [String, Array],
      required: true,
      deafult: () => [],
    },
  },
  data() {
    return {
      sortBy: 'datetime',
      sortDesc: true,
      sortDirection: 'asc',
      currency: currencyFormatter,
      getFormattedDate,
      getFormattedDateTime,
    }
  },
  computed: {
    totalAmount() {
      let totalAmount = 0
      this.rows.forEach(i => {
        totalAmount += i.ecpm
      })
      return totalAmount
    },
  },
  methods: {
    totalFormat(field) {
      let total = 0
      this.rows.forEach(i => {
        total += i[field]
      })
      return total
    },
  },
}
</script>
