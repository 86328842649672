<template>
  <div v-if="rows.length !== 0">
    <app-collapse
      accordion
      type="border"
      class="mt-2 collapse-sites"
    >
      <app-collapse-item
        v-for="(row, index) in rowsReverse"
        :key="`key-${index}`"
        :title="getFormattedDate(new Date(row.datetime))"
        :is-visible="!index"
      >
        <b-table
          small
          :items="row.stats"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          responsive
          striped
        >
          <template #cell(obj)="data">
            {{ data.value }}
          </template>

          <template #cell(impressions)="data">
            {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
          </template>

          <template #cell(clicks)="data">
            {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
          </template>

          <template #cell(ecpm)="data">
            {{ currency('USD', data.value) }}
          </template>
        </b-table>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>
<script>
import {
  BTable,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import currencyFormatter from '@/utils/currency-formatter'
import getFormattedDate, { getFormattedDateTime } from '@/utils/date-formatter'

export default {
  components: {
    BTable,
    AppCollapse,
    AppCollapseItem,
  },
  props: {
    rows: {
      type: Array,
      required: true,
      deafult: () => [],
    },
    fields: {
      type: Array,
      required: true,
      deafult: () => [],
    },
    range: {
      type: [String, Array],
      required: true,
      deafult: () => [],
    },
  },
  data() {
    return {
      sites: [],
      sortBy: 'datetime',
      sortDesc: true,
      sortDirection: 'asc',
      currency: currencyFormatter,
      getFormattedDate,
      getFormattedDateTime,
    }
  },
  computed: {
    rowsReverse() {
      const rowsitems = this.rows
      return rowsitems.reverse()
    },
    totalAmount() {
      let totalAmount = 0
      this.rows.forEach(i => {
        totalAmount += i.ecpm
      })
      return totalAmount
    },
  },
  created() {
    // if (this.sites.length === 0) {
    //   this.sites = this.$store.getters['dashboard-sites/sites']
    // }
  },
  methods: {
    totalFormat(field) {
      let total = 0
      this.rows.forEach(i => {
        total += i[field]
      })
      return total
    },
    getSiteData(id, key) {
      const [sites] = this.sites.filter(s => s._id === id)
      return sites[key] || ''
    },
  },
}
</script>
