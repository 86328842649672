<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <b-card>
      <DspPartnerToolbar
        v-if="Object.keys(dsppartner).length !== 0"
        :dsppartner="dsppartner"
        closing
      />
      <hr>
      <div class="table-filters">
        <div class="col-type">
          <b-form-radio-group
            id="type-report"
            v-model="typeStats"
            class="mb-1"
            button-variant="outline-primary"
            :options="optionsRadioLocale"
            buttons
            size="sm"
            name="radios-btn-default"
            @change="changeType"
          />
        </div>
        <div
          class="col-picker"
        >
          <div class="d-flex align-items-center">
            <feather-icon
              icon="CalendarIcon"
              size="16"
            />
            <flat-pickr
              v-model="rangePicker"
              :config="{ mode: 'range'}"
              class="form-control flat-picker bg-transparent border-0 shadow-none"
              placeholder="YYYY-MM-DD"
              @on-change="getPartnerStatsByType"
            />
          </div>
        </div>
      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <div v-else-if="rows.length !== 0">
        <component
          :is="`TableStats${componentName}`"
          :key="typeStats"
          :fields="tableHeadersLocale[typeStats]"
          :range="rangePicker"
          :rows="rows"
        />
      </div>
      <div
        v-else
        class="alert-body  text-center"
      >
        <span>{{ $t('No data for this period') }}</span>
      </div>
    </b-card>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import flatPickr from 'vue-flatpickr-component'
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BFormRadioGroup,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import getFormattedDate, { getFormattedDateTime } from '@/utils/date-formatter'
import TableStatsOverview from './TableStatsOverview.vue'
import TableStatsCampaign from './TableStatsCampaign.vue'
import TableStatsSite from './TableStatsSite.vue'
import DspPartnerToolbar from './DspPartnerToolbar.vue'

export default {
  components: {
    Breadcrumbs,
    BCard,
    BRow,
    BCol,
    BTable,
    BFormRadioGroup,
    BSpinner,
    TableStatsOverview,
    TableStatsCampaign,
    TableStatsSite,
    flatPickr,
    DspPartnerToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: true,
      pageTitle: '',
      pageSubtitle: '',
      breadcrumbItems: [],
      typeStats: 'overview',
      dsppartner: {},
      rows: [],
      getFormattedDate,
      getFormattedDateTime,
      optionsRadio: [
        { text: 'Overview', key: 'overview', value: 'overview' },
        { text: 'Campaign', key: 'campaign', value: 'campaign' },
        { text: 'Site', key: 'site', value: 'site' },
      ],
      fields: {
        overview: [
          { key: 'datetime', label: 'Date' },
          { key: 'impressions', label: 'Impressions' },
          { key: 'clicks', label: 'Clicks' },
          { key: 'ecpm', label: 'Revenue' },
        ],
        campaign: [
          { key: 'obj', label: 'Campaign' },
          { key: 'impressions', label: 'Impressions' },
          { key: 'clicks', label: 'Clicks' },
          { key: 'ecpm', label: 'Revenue' },
        ],
        site: [
          { key: 'obj', label: 'Site' },
          { key: 'impressions', label: 'Impressions' },
          { key: 'clicks', label: 'Clicks' },
          { key: 'ecpm', label: 'Revenue' },
        ],
      },
      rangePicker: [],
      rangeDefault: [getFormattedDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)), getFormattedDate(new Date())],
    }
  },
  computed: {
    componentName() {
      return this.typeStats.charAt(0).toUpperCase() + this.typeStats.slice(1)
    },
    optionsRadioLocale() {
      return this.optionsRadio.map(o => ({
        value: o.value,
        text: this.$t(`page.features.${o.value}`),
      }))
    },
    tableHeadersLocale() {
      const fields = {}
      Object.keys(this.fields).forEach(key => {
        fields[key] = this.fields[key].map(h => ({
          ...h,
          label: this.$t(`reportsTableHeaders.${key}.${h.key}`),
        }))
      })
      return fields
    },
  },
  async created() {
    await store.dispatch('dashboard-campaings/fetchCampaigns')
    // await store.dispatch('dashboard-sites/fetchSites')
    this.rangePicker = this.rangeDefault
    this.getPartnerData()
  },
  methods: {
    setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('DSP partners'),
          to: '/dsppartners/',
        },
        {
          text: this.dsppartner.name,
          to: `/dsppartners/${this.dsppartner._id}/`,
        },
        {
          text: this.$t('Statistics'),
          active: true,
        },
      ]
    },
    async getPartnerData() {
      const responseData = await useJwt.getDsppartner(this.$route.params.id)
      this.dsppartner = responseData.data.dsppartner || {}
      this.setBreabcrumbs()
    },
    async getPartnerStatsByType() {
      this.loading = true
      this.rows = []
      let dateFrom
      let dateTo

      const urlSearchParams = new URLSearchParams(window.location.search)
      const urlParams = Object.fromEntries(urlSearchParams.entries())
      if (urlParams.kind) {
        this.typeStats = urlParams.kind
      }

      const selectedRange = this.rangePicker
      if (Array.isArray(selectedRange)) {
        [dateFrom, dateTo] = selectedRange
      } else {
        [dateFrom, , dateTo] = selectedRange.split(' ')
      }

      if (!dateTo && !dateFrom) {
        [dateFrom, dateTo] = this.rangeDefault
      }

      if (dateTo && dateFrom) {
        const params = {
          from: dateFrom,
          to: dateTo,
          kind: this.typeStats,
        }

        const responseData = await useJwt.getDsppartnerStatsByType(this.$route.params.id, params)
        this.rows = responseData.data.dsppartnerstats || []
      }
      this.loading = false
    },
    async changeType() {
      this.rows = []
      this.$router.replace({
        name: 'dashboard-dsppartner-stats',
        query: { kind: this.typeStats },
      })
      this.getPartnerStatsByType()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
  .table-filters {
    display: flex;
    justify-content: space-between;
  }

  tfoot th {
    background-color: #fff!important;
  }

  .col-picker {
    min-width: 220px;
  }

  @media (max-width: 1400px) {
    .table-filters {
      flex-wrap: wrap;
      margin-bottom: 15px;
    }

    .col-type {
      width: 60%;
    }
  }

  @media (max-width: 992px) {
    .col-type {
      width: 70%;
    }
  }

  @media (max-width: 800px) {
    .col-type {
      width: 100%;
    }
  }
</style>
