<template>
  <div v-if="rows.length !== 0">
    <div
      v-for="row in rows"
      :key="row.datetime"
    >
      <h4>{{ getFormattedDate(new Date(row.datetime)) }}</h4>
      <b-table
        small
        :items="row.stats"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        responsive
        striped
      >
        <template #cell(obj)="data">
          <span v-if="campaigns.length !== 0">
            <router-link :to="`/users/${data.item.campaign_user_id}/campaigns/${data.value}`">{{ data.item.campaign_name }}</router-link>
          </span>
          <span v-else>{{ data.value }}</span>
        </template>

        <template #cell(impressions)="data">
          {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
        </template>

        <template #cell(clicks)="data">
          {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
        </template>

        <template #cell(ecpm)="data">
          {{ currency('USD', data.value) }}
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BTable,
} from 'bootstrap-vue'
import currencyFormatter from '@/utils/currency-formatter'
import getFormattedDate, { getFormattedDateTime } from '@/utils/date-formatter'

export default {
  components: {
    BTable,
  },
  props: {
    rows: {
      type: Array,
      deep: true,
      required: true,
      deafult: () => [],
    },
    fields: {
      type: Array,
      required: true,
      deafult: () => [],
    },
    range: {
      type: [String, Array],
      required: true,
      deafult: () => [],
    },
  },
  data() {
    return {
      compaignsRows: [],
      campaigns: [],
      sortBy: 'datetime',
      sortDesc: true,
      sortDirection: 'asc',
      currency: currencyFormatter,
      getFormattedDate,
      getFormattedDateTime,
      rangeDefault: [getFormattedDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)), getFormattedDate(new Date())],
    }
  },
  computed: {
    totalAmount() {
      let totalAmount = 0
      this.rows.forEach(i => {
        totalAmount += i.ecpm
      })
      return totalAmount
    },
  },
  created() {
    if (this.campaigns.length === 0) {
      this.campaigns = this.$store.getters['dashboard-campaings/campaigns']
    }
  },
  methods: {
    async getPartnerStatsByType2() {
      let dateFrom
      let dateTo
      if (Array.isArray(this.range)) {
        [dateFrom, dateTo] = this.range
      } else {
        [dateFrom, , dateTo] = this.range.split(' ')
      }

      if (!dateTo) {
        [dateFrom, dateTo] = this.rangeDefault
      }

      if (dateTo && dateFrom) {
        const params = {
          from: dateFrom,
          to: dateTo,
          kind: 'campaign',
        }

        const responseData = await useJwt.getDsppartnerStatsByType(this.$route.params.id, params)
        this.compaignsRows = responseData.data.dsppartnerstats || []
      }
    },
    getCompaignData(id, key) {
      const [campaign] = this.campaigns.filter(c => c._id === id)
      return campaign[key] || ''
    },
    totalFormat(field) {
      let total = 0
      this.rows.forEach(i => {
        total += i[field]
      })
      return total
    },
  },
}
</script>
